@charset "utf-8";
@media screen and (min-width: 701px) {
    #SuperHeaderContainer{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        position: block;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer{
        margin: 0;
        padding: 1vh 0;
        display: block;
        width: 100%;
        background-color: #F6F6F6;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #PhoneHeaderLink,
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #MessageHeaderParagraph,
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #RightSiteHeaderContainer{
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #PhoneHeaderLink{
        text-decoration: none;
        text-align: left;
        width: 24%;
        margin-left: 12.5%;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #MessageHeaderParagraph{
        text-decoration: none;
        text-align: left;
        color: #ffffff;
        background-color: #013C76;
        font-size: 1em;
        text-align: center;
        border-radius: 30px 30px 30px 30px;
        -moz-border-radius: 30px 30px 30px 30px;
        -webkit-border-radius: 30px 30px 30px 30px;
        border: 0px solid #000000;
        padding: 10px 20px;
        margin-left: 4%;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #PhoneHeaderLink #PhoneHeaderImage,
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #PhoneHeaderLink #PhoneHeaderMessage{
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #PhoneHeaderLink #PhoneHeaderImage{
        width: 10px;
        margin-right: 1px;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #PhoneHeaderLink #PhoneHeaderMessage{
        color: rgb(66,78,104);
        font-size: .9em;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #RightSiteHeaderContainer{
        text-align: right;
        width: 20%;
        margin-right: 5%;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #RightSiteHeaderContainer #SocialMediaHeaderList{
        margin: 0;
        padding: 0;
        display: inline-block;
        list-style: none;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #RightSiteHeaderContainer #SocialMediaHeaderList .SocialMediaHeaderListItem{
        margin: 0;
        padding: 0 7px;
        display: inline-block;
        vertical-align: middle;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #RightSiteHeaderContainer #SocialMediaHeaderList .SocialMediaHeaderListItem .SocialMediaHeaderListItem_Link{
        margin: 0;
        padding: 0;
        display: block;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #RightSiteHeaderContainer #SocialMediaHeaderList .SocialMediaHeaderListItem .SocialMediaHeaderListItem_Link .SocialMediaHeaderListItem_Image{
        height: 17px;
    }
    /*------------------------------------------------*/
    /*------------------------------------------------*/
    /*------------------------------------------------*/
    #SuperHeaderContainer #MenuSuperContainer{
        margin: 0;
        padding: 2vh 0;
        display: block;
        width: 100%;
        text-align: center;
        background-color: #FFFFFF;
    }
    #SuperHeaderContainer #MenuSuperContainer #MenuSuperContainerBussinesLogo,
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList,
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuSearchContainer,
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer{
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
    }
    #SuperHeaderContainer #MenuSuperContainer #MobilMenuButtonIcon{
        display: none!important;
    }
    /*#SuperHeaderContainer #MenuSuperContainer #MenuSuperContainerBussinesLogo{

    }*/
    #SuperHeaderContainer #MenuSuperContainer #MenuSuperContainerBussinesLogo img{
        margin: 0;
        padding: 0;
        display: block;
        width: 180px;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList{
        margin: 0;
        padding: 0 0;
        list-style: none;
        width: 50%;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList #MenuSuperContainerBussinesLogo{
        display: none!important;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList #CloseMobileMenu{
        display: none !important;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem{
        margin: 0 10px;
        padding: 5px 2%;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
        transition: .3s;
        border-bottom: 2px solid #ffffff;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem:hover,
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem:focus,
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItemSelected{
        border-bottom: 2px solid #00C7D6 !important;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem p{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        color: #999999;
        font-size: 1.1em;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList{
        margin: 0;
        padding: 3vh 0;
        display: none;
        width: 100%;
        position: absolute;
        background-color: rgba(255, 255, 255, .99);
        left: 0;
        margin-top: 20px;
        transition: .3s;
        list-style: none;
        text-align: center;
        z-index: 100;
        -webkit-box-shadow: 0px 3px 5px 0px rgba(204,204,204,1);
        -moz-box-shadow: 0px 3px 5px 0px rgba(204,204,204,1);
        box-shadow: 0px 3px 5px 0px rgba(204,204,204,1);
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItem{
        margin: 2vh 2%;
        padding: 0;
        display: inline-block;
        vertical-align: top;
        text-align: left;
        width: 20%;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItemTitle{
        margin: 0 0 1vh;
        padding: 1vh 0 5px 0;
        display: block;
        width: 100%;
        font-weight: bold;
        font-size: .9em;
        border-bottom: 1px solid #999999;
        text-align: left;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItem .SuperMenuLinesListItemCategoriesListItemProductList{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItem .SuperMenuLinesListItemCategoriesListItemProductList .SuperMenuLinesListItemCategoriesListItemProductListItem{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItem .SuperMenuLinesListItemCategoriesListItemProductList .SuperMenuLinesListItemCategoriesListItemProductListItemTitle{
        margin: 0;
        padding: 0 0 1vh 0;
        display: block;
        width: 100%;
        font-size: .9em;
        text-align: left;
        transition: .3s;
        text-decoration: none;
        color: #444444;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItem .SuperMenuLinesListItemCategoriesListItemProductList .SuperMenuLinesListItemCategoriesListItemProductListItemTitle:hover,
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItem .SuperMenuLinesListItemCategoriesListItemProductList .SuperMenuLinesListItemCategoriesListItemProductListItemTitle:focus{
        color: #00C7D6;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuSearchContainer{
        border: 1px solid #CCCCCC;
        border-radius: 25px;
        padding: 7px 15px;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuSearchContainer #SuperMenuSearchInput,
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuSearchContainer #SuperMenuSearchIcon{
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuSearchContainer #SuperMenuSearchInput{
        margin: 0;
        padding: 0;
        border: none;
        text-align: left;
        color: #999999;
        width: 10vw;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuSearchContainer #SuperMenuSearchInput::placeholder{
        color: #999999;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuSearchContainer #SuperMenuSearchIcon{
        width: 15px;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer{
        cursor: pointer;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer:hover #SuperMenuShoppingCartNumbers,
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer:focus #SuperMenuShoppingCartNumbers{
        transform: scale(1.2);
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer #SuperMenuShoppingCartImage{
        width: 25px;
        transition: .3s;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer #SuperMenuShoppingCartNumbers{
        border-radius: 50%;
        color: #ffffff;
        background-color: #00C7D6;
        text-align: center;
        font-size: .5em;
        padding: 5px;
        margin: 0px -10px -10px auto;
        width: 10px;
        position: relative;
        top: 0%;
        right: 0%;
        text-align: center;
        transition: .1s;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer .SuperMenuShoppingCartNumbersAnimated{
        transform: scale(3);
    }
}
@media screen and (max-width: 700px) {
	#SuperHeaderContainer{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 100;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer{
        display: none !important;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #PhoneHeaderLink,
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #RightSiteHeaderContainer{
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #PhoneHeaderLink{
        text-decoration: none;
        text-align: left;
        width: 53%;
        margin-left: 13.5%;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #PhoneHeaderLink #PhoneHeaderImage,
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #PhoneHeaderLink #PhoneHeaderMessage{
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #PhoneHeaderLink #PhoneHeaderImage{
        width: 10px;
        margin-right: 1px;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #PhoneHeaderLink #PhoneHeaderMessage{
        color: rgb(66,78,104);
        font-size: .9em;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #RightSiteHeaderContainer{
        text-align: right;
        width: 20%;
        margin-right: 5%;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #RightSiteHeaderContainer #SocialMediaHeaderList{
        margin: 0;
        padding: 0;
        display: inline-block;
        list-style: none;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #RightSiteHeaderContainer #SocialMediaHeaderList .SocialMediaHeaderListItem{
        margin: 0;
        padding: 0 7px;
        display: inline-block;
        vertical-align: middle;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #RightSiteHeaderContainer #SocialMediaHeaderList .SocialMediaHeaderListItem .SocialMediaHeaderListItem_Link{
        margin: 0;
        padding: 0;
        display: block;
    }
    #SuperHeaderContainer #SocialMediaSuperHeaderContainer #RightSiteHeaderContainer #SocialMediaHeaderList .SocialMediaHeaderListItem .SocialMediaHeaderListItem_Link .SocialMediaHeaderListItem_Image{
        height: 17px;
    }
    /*------------------------------------------------*/
    /*------------------------------------------------*/
    /*------------------------------------------------*/
    #SuperHeaderContainer #MenuSuperContainer{
        margin: 0;
        padding: 2vh 0;
        display: block;
        width: 100%;
        text-align: center;
        background-color: #FFFFFF;
    }
    #SuperHeaderContainer #MenuSuperContainer #MenuSuperContainerBussinesLogo{
        margin: auto;
        padding: 0;
        display: block;
        width: 120px;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer{
       position: fixed;
       bottom: 1%;
       right: 3%;
       z-index: 100;
       background-color: rgba(255, 255, 255, .95);
       padding: 15px 22px;
       border-radius: 50%;
       -webkit-box-shadow: 0px 0px 5px 0px rgba(210,247,255,1);
        -moz-box-shadow: 0px 0px 5px 0px rgba(210,247,255,1);
        box-shadow: 0px 0px 5px 0px rgba(210,247,255,1);
    }
    #SuperHeaderContainer #MenuSuperContainer #MobilMenuButtonIcon{
        margin: 0;
        padding: 0;
        display: block;
        width: 25px;
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
    }
    /*#SuperHeaderContainer #MenuSuperContainer #MenuSuperContainerBussinesLogo{

    }*/
    #SuperHeaderContainer #MenuSuperContainer #MenuSuperContainerBussinesLogo img{
        width: 100%;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList{
        margin: 0;
        padding:2vh 0 0 0;
        list-style: none;
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: -100%;
        background-color: rgba(255, 255, 255, .99);
        z-index: 50;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList #CloseMobileMenu{
        margin: 0;
        padding: 0;
        display: block !important;
        position: absolute;
        top: 5px;
        right: 15px;
        color: #444444;
        cursor: pointer;
        background-color: transparent;
        border: none;
        font-size: 1.5em;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem{
        margin: 2vh auto 0 5%;
        padding: 5px 0;
        display: block;
        text-align: left;
        cursor: pointer;
        width: 80%;
        transition: .3s;
        border-bottom: 1px solid #cccccc !important;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemLineParagraph{
        font-size: 1.6em;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem:hover,
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem:focus,
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItemSelected{
        border-bottom: 1px solid #00C7D6 !important;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem p{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        color: #999999;
        font-size: 1.1em;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList{
        margin: 0 auto 0 5%;
        padding: 0 0;
        display: none;
        width: 90%;
        list-style: none;
        text-align: left;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItem{
        margin: 10px 0;
        padding: 0;
        display: block;
        width: 100%;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItemTitle{
        margin: 0 0 1vh;
        padding: 1vh 0 5px 0;
        display: block;
        width: 100%;
        font-weight: bold;
        font-size: .9em;
        border-bottom: 1px solid #999999;
        text-align: left;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItem .SuperMenuLinesListItemCategoriesListItemProductList{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItem .SuperMenuLinesListItemCategoriesListItemProductList .SuperMenuLinesListItemCategoriesListItemProductListItem{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItem .SuperMenuLinesListItemCategoriesListItemProductList .SuperMenuLinesListItemCategoriesListItemProductListItemTitle{
        margin: 0;
        padding: 0 0 1vh 0;
        display: block;
        width: 100%;
        font-size: .9em;
        text-align: left;
        transition: .3s;
        text-decoration: none;
        color: #444444;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItem .SuperMenuLinesListItemCategoriesListItemProductList .SuperMenuLinesListItemCategoriesListItemProductListItemTitle:hover,
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItem .SuperMenuLinesListItemCategoriesListItemProductList .SuperMenuLinesListItemCategoriesListItemProductListItemTitle:focus{
        color: #00C7D6;
    }
    
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuSearchContainer{
        margin: 1vh auto;
        padding: .5vh 0;
        width: 70%;
        min-width: 100px;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuSearchContainer{
        border: none;
        padding: 0;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuSearchContainer #SuperMenuSearchIcon{
        display: none !important;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuSearchContainer #SuperMenuSearchInput{
        margin: 0;
        padding: 0;
        border: none;
        text-align: center;
        color: #999999;
        width: 100%;
        font-size: 1.1em;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuSearchContainer #SuperMenuSearchInput::placeholder{
        color: #999999;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuSearchContainer #SuperMenuSearchIcon{
        width: 15px;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer{
        cursor: pointer;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer:hover #SuperMenuShoppingCartNumbers,
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer:focus #SuperMenuShoppingCartNumbers{
        transform: scale(1.2);
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer #SuperMenuShoppingCartImage{
        width: 45px;
        transition: .3s;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer #SuperMenuShoppingCartNumbers{
        border-radius: 50%;
        color: #ffffff;
        background-color: #00C7D6;
        text-align: center;
        font-size: .5em;
        padding: 5px;
        margin: 0px -10px -10px auto;
        width: 10px;
        position: relative;
        top: 0%;
        right: 0%;
        text-align: center;
        transition: .1s;
    }
    #SuperHeaderContainer #MenuSuperContainer #SuperMenuShoppingCartContainer .SuperMenuShoppingCartNumbersAnimated{
        transform: scale(3);
    }
}
